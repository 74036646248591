import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input as ReactInput, Label, Spinner, Col, Card, CardBody } from "reactstrap";
import { t } from "i18next";
import StateSelect from "../Components/StateSelect";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import { Controller, useFieldArray } from "react-hook-form";
import {
  ProductsPut,
  getAttributesId,
  getProductsId,
  uploadFiles,
} from "../../../api";
import Dropzone from "react-dropzone";
import Visibility from "../Components/Visibility";
import schemaFieldTypes from "../../../utils/validationSchema";
import * as yup from "yup";
import { ToastContainer } from "react-toastify";
import { Textarea } from "../../../Components/atoms/textarea";
import { InputLabel } from "../../../Components/atoms/inputLabel";
import { layoutModeTypes } from "../../../Components/constants/layout";
const values = {
  description: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  categoryId: null,
  attrs: [],
  prices: [
    { type: "Price", value: "" },
    { type: "Vat", value: "" },
    { type: "Sale", value: "" },
  ],
  isVisible: null,
  files: [],
};

const ProductsEdit = () => {
  const createSchema = () => {
    return yup.object().shape({
      attribute: yup.array().of(
        yup.object().shape({
          value: yup.mixed().when("isRequired", {
            is: true,
            then: yup
              .mixed()
              .when("filter.filterType", {
                is: (val) => val === "Range" || val === "Price",
                then: yup.string().required(t("required_field_error")),
              })
              .when("filter.filterType", {
                is: (val) => val === "SingleSelect",
                then: yup.string().required(t("required_field_error")),
              })
              .when("filter.filterType", {
                is: (val) => val === "MultipleSelect",
                then: yup.string().required(t("At least one must be selected")),
                otherwise: yup.string().nullable(),
              }),
            otherwise: yup.string().nullable(),
          }),
          valueTranslations: yup.mixed().when("isRequired", {
            is: true,
            then: yup.mixed().when("isValueTranslated", {
              is: true,
              then: yup.object().shape({
                ru: yup.string().required(t("required_field_error")),
                "uz-Cyrl-UZ": yup.string().required(t("required_field_error")),
                "uz-Latn-UZ": yup.string().required(t("required_field_error")),
              }),
              otherwise: yup.object().nullable(),
            }),
            otherwise: yup.object().nullable(),
          }),
        })
      ),
      files: yup
        .array()
        .test("files-required", t("At least one must be selected"), (value) => {
          return value && value.length > 0;
        }),
    });
  };

  const schema = createSchema();
  const { id } = useParams();

  const [filePreviews, setFilePreviews] = useState([]);
  const [fileInputs, setFileInputs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [saleTypeState, setSaleTypeState] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const errorMessageRef = useRef(null);
  const maxFileSize = 10 * 1024 * 1024;
  const navigate = useNavigate();
  const clients = useQueryClient();
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    setError,
    formState: { errors },
  } = useHookForm(values, schema);
  const { data } = useQuery({
    queryKey: ["productsEdit", id],
    queryFn: () => getProductsId(id),
  });
 





  const products = data?.result;
  const categoryId = data?.result?.categoryId;
  const { data: attributes } = useQuery({
    queryKey: ["categoryId", categoryId],
    queryFn: () => getAttributesId(categoryId),
    enabled: !!categoryId,
    
  });

  
  
  
  const allPrice = products?.variations?.[0].prices?.filter(
    (item) =>
      item.type !== "Price" && item.type !== "Vat" && item.type !== "Sale"
  );



  function getLastPartOfUrl(url) {
    if (url.length > 36) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    } else {
      return url;
    }
  }

  const handleMultipleSelectChange = (field, value, e) => {
    let newValue = field.value.split(",").filter(Boolean);
    if (e.target.checked) {
      newValue.push(value.trim());
    } else {
      newValue = newValue.filter((v) => v !== value.trim());
    }
    field.onChange(newValue.join(","));
  };

  const onDropAccepted = (acceptedFiles) => {
  
    if (errorMessageRef.current) {
      errorMessageRef.current.textContent = ""; // Xato xabarini tozalash
    }
    handleFileUpload(acceptedFiles); // Faylni yuklash
  };
  
  const onDropRejected = (rejectedFiles) => {
    const hasFileTooLarge = rejectedFiles.some((file) =>
      file.errors.some((error) => error.code === "file-too-large")
    );
   
    
    if (hasFileTooLarge) {
      if (errorMessageRef.current) {
        const fileSizeInMB = (rejectedFiles[0].file.size / (1024 * 1024)).toFixed(2);
        errorMessageRef.current.textContent = t("File size must not exceed 10 MB!") + " " +  `File size ${fileSizeInMB} MB`; 
      }
    }
  };

 
  


  const handleFileUpload = (acceptedFiles) => {
    const newFiles = [...watch("files")]; 
    const updatedPreviews = [...filePreviews]; 
    const updatedInputs = [...fileInputs]; 
  
    acceptedFiles.forEach((file, index) => {
      newFiles.push({ file });
  
      updatedPreviews.push(URL.createObjectURL(file));
  
      updatedInputs.push(updatedInputs.length);
    });
  
    setValue("files", newFiles);
    setFilePreviews(updatedPreviews);
    setFileInputs(updatedInputs);
  };
  

  const handleDeleteImage = (index) => {
    const currentFiles = [...watch("files")];
    currentFiles.splice(index, 1);
    setValue("files", currentFiles);

    const updatedPreviews = [...filePreviews];
    updatedPreviews.splice(index, 1);
    setFilePreviews(updatedPreviews);

    const updatedInputs = [...fileInputs];
    updatedInputs.splice(index, 1);
    setFileInputs(updatedInputs);
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (index) => {
    if (draggedIndex === null || draggedIndex === index) return;

    const updatedPreviews = [...filePreviews];
    const draggedPreview = updatedPreviews[draggedIndex];
    updatedPreviews.splice(draggedIndex, 1); 
    updatedPreviews.splice(index, 0, draggedPreview); 

    const updatedFiles = [...watch("files")];
    const draggedFile = updatedFiles[draggedIndex];
    updatedFiles.splice(draggedIndex, 1); 
    updatedFiles.splice(index, 0, draggedFile); 

    setFilePreviews(updatedPreviews);
    setValue("files", updatedFiles);

    setDraggedIndex(null); 
  };


  const Submit = async (res) => {
   
    
    if (!res?.prices?.[0].value) {
      return setError("prices[0].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }

    if (!res?.prices?.[1].value) {
      return setError("prices[1].value", {
        type: "manual",
        message: t("required_field_error"),
      });
    }
    if (
      res.prices[2].value !== "" &&
      +res.prices[0].value > +res.prices[2].value
    ) {
      return setError("prices[2].value", {
        type: "manual",
        message: t("price_2_error"),
      });
    }
    setLoading(true);

    try {
      const newFiles = watch("files");
      const uploadedFiles = [];

      for (let index = 0; index < newFiles.length; index++) {
        const hasPreview = newFiles[index]?.url;
        if (!hasPreview) {
          const file = newFiles[index];

          const formData = new FormData();
          formData.append("files", file.file);

          const resImage = await uploadFiles(formData);
          const imageObj = resImage?.[0];

          uploadedFiles.push({
            order: index + 1,
            url: imageObj?.url,
            fileId: imageObj?.id,
            variationId: products?.id,
            isVisible: true,
            productId: products?.variations?.[0]?.productId,
          });
        } else {
          uploadedFiles.push({
            ...newFiles[index],
          });
        }
      }
      const descriptions = Object.keys(res.description).map((languageCode) => ({
        languageCode,
        text: res.description[languageCode],
      }));

      const variations = [
        {
          count: 1000,
          compensationOnly: false,
          productId: id,
          attributeValues: Object.keys(res?.attrs)?.map((attrId) => ({
            attributeId: parseInt(attrId),
            value: res?.attrs[attrId].value || "",
            productId: id,
            isVisible: true,
            valueTranslations: [
              {
                languageCode: "ru",
                text: res?.attrs[attrId].valueTranslations?.ru || "",
              },
              {
                languageCode: "uz-Latn-UZ",
                text:
                  res?.attrs[attrId].valueTranslations?.["uz-Latn-UZ"] || "",
              },
              {
                languageCode: "uz-Cyrl-UZ",
                text:
                  res?.attrs[attrId].valueTranslations?.["uz-Cyrl-UZ"] || "",
              },
            ],
            variationId: products?.variations?.[0]?.id,
          })),

          prices: [
            ...(res.prices?.map((item) => ({
              currencyId: 1,
              value: item.value === "" ? 0 : item.value,
              variationId: products?.variations?.[0]?.id,
              type: item.type,
            })) || []),
            ...(allPrice?.map((item) => ({
              currencyId: 1,
              value: item.value === "" ? 0 : item.value,
              variationId: products?.variations?.[0]?.id,
              type: item.type,
            })) || []),
          ],

          isVisible: products?.isVisible,
          files: uploadedFiles,
          saleType: saleTypeState.reduce((acc, type) => acc | type, 0),
        },
      ];

      const datas = {
        description: descriptions,
        productId: id,
        variations: variations,
      };

      await ProductsPut(datas);
      clients.invalidateQueries({ queryKey: ["products"] });
      clients.invalidateQueries({ queryKey: ["moderations"] });
      navigate("/products-moderate");
    } catch (error) {
      alert(error?.data?.errors?.errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const getTextByLanguageCode = (descriptions, languageCode) => {
    const description = descriptions.find(
      (desc) => desc.languageCode === languageCode
    );
    return description ? description.text : "";
  };

  const handleSaleTypeChange = (type) => {
    setSaleTypeState((prevState) => {
      if (prevState.includes(type)) {
        return prevState.filter((t) => t !== type);
      } else {
        return [...prevState, type];
      }
    });
  };
  const getInitialSaleTypeState = (productSaleTypes) => {
    const saleTypes = [];
    if (productSaleTypes & 1) saleTypes.push(1);
    if (productSaleTypes & 2) saleTypes.push(2);
    if (productSaleTypes & 4) saleTypes.push(4);
    if (productSaleTypes & 8) saleTypes.push(8);
    return saleTypes;
  };
  useEffect(() => {
    if (products) {
      const descriptions = products.descriptions;
      setValue("description.ru", getTextByLanguageCode(descriptions, "ru"));
      setValue("description.en", getTextByLanguageCode(descriptions, "en"));
      setValue(
        "description.uz-Cyrl-UZ",
        getTextByLanguageCode(descriptions, "uz-Cyrl-UZ")
      );
      setValue(
        "description.uz-Latn-UZ",
        getTextByLanguageCode(descriptions, "uz-Latn-UZ")
      );

      products.variations?.[0]?.attributeValues?.forEach((attrValue) => {
        setValue(`attrs.${attrValue.attributeId}.value`, attrValue.value || "");

        if (attrValue.valueTranslations) {
          setValue(
            `attrs.${attrValue.attributeId}.valueTranslations.ru`,
            getTextByLanguageCode(attrValue.valueTranslations, "ru")
          );
          setValue(
            `attrs.${attrValue.attributeId}.valueTranslations.uz-Latn-UZ`,
            getTextByLanguageCode(attrValue.valueTranslations, "uz-Latn-UZ")
          );
          setValue(
            `attrs.${attrValue.attributeId}.valueTranslations.uz-Cyrl-UZ`,
            getTextByLanguageCode(attrValue.valueTranslations, "uz-Cyrl-UZ")
          );
        }
      });
      const existingFiles = products?.variations?.[0]?.files || [];
      const previews = existingFiles.map((file) => file.url);
      setFilePreviews(previews);
      setFileInputs([...Array(existingFiles.length + 1).keys()]);

      const formattedExistingFiles = existingFiles.map((file) => ({
        order: file.order,
        url: file.url,
        fileId: getLastPartOfUrl(file.url),
        variationId: products?.id,
        productId: products?.variations?.[0]?.productId,
        isVisible: true,
      }));

      setValue("files", formattedExistingFiles);

      setValue(
        "prices[0].value",
        products?.variations?.[0]?.prices[0]?.value || ""
      );
      setValue(
        "prices[1].value",
        products?.variations?.[0]?.prices[1]?.value || ""
      );
      setValue(
        "prices[2].value",
        products?.variations?.[0]?.prices[2]?.value || ""
      );
      if (products?.variations?.[0]?.saleType !== undefined) {
        setSaleTypeState(
          getInitialSaleTypeState(products.variations[0].saleType)
        );
      }
    }
    
  }, [products, setValue]);
  
  

  return (
    <div className="page-content ">
      <ToastContainer position="top-center" />
     <Col className="d-flex justify-content-center">
     <Card className="w-75">
      <CardBody>
      <div className="d-flex align-items-start gap-3">
        <i
          className="ri-arrow-left-line fs-3 cursor-pointer"
          onClick={() => navigate(-1)}
        ></i>
        <h2>{t("Editing a product")}</h2>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="d-flex gap-3 flex-column">
          <div>
            <Label className="fw-semibold">{t("Product status")}</Label>
            <StateSelect id={id} data={data} />
          </div>
          <div>
            <Label className="fw-semibold">{t("Visible")}</Label>
            <Visibility id={id} data={data} />
          </div>
        </div>
        <div>
          <Label className="fw-semibold">{t("Product category")}</Label>
          <ReactInput
            className="form-control border-dashed p-3"
            value={data?.result?.category?.name}
            readOnly
          />
        </div>

        <div className="d-flex flex-column gap-3">
          {data?.result?.names
            ?.filter(
              (item) =>
                item.languageCode !== "en" && item.languageCode !== "uz-Cyrl-QQ"
            )
            .map((item) => (
              <div key={item.id}>
                <Label className="fw-semibold">
                  {item.languageCode === "ru"
                    ? t("Name Russian language")
                    : item.languageCode === "uz-Cyrl-UZ"
                    ? t("Name Uzbek language (Cyrill.)")
                    : item.languageCode === "uz-Latn-UZ"
                    ? t("Name Uzbek language (lat.)")
                    : item.languageCode === "en"
                    ? t("name_en")
                    : "test language"}
                </Label>
                <ReactInput
                  className="form-control border-dashed p-3"
                  value={item.text}
                  readOnly
                />
              </div>
            ))}
        </div>
      </div>

      <Form
        onSubmit={handleSubmit(Submit)}
        className="d-flex flex-column gap-3 mt-3"
      >
        <div>
          <Label className="fw-semibold">{t("description_ru")}</Label>
          <Textarea control={control} name="description.ru" />
        </div>
        <div>
          <Label className="fw-semibold">{t("description_cyrl")}</Label>
          <Textarea control={control} name="description.uz-Cyrl-UZ" />
        </div>
        <div>
          <Label className="fw-semibold">{t("description_lat")}</Label>
          <Textarea control={control} name="description.uz-Latn-UZ" />
        </div>
        <div className="d-flex gap-3 align-items-center">
          <div className="w-100">
            <Label className="fw-semibold">
              {t("Price")}
              <span className="text-danger">*</span>
            </Label>
            <Controller
              name="prices[0].value"
              control={control}
              render={({ field }) => (
                <ReactInput
                  {...field}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
            {errors.prices?.[0]?.value && (
              <div className="text-danger">
                {t(errors.prices[0].value.message)}
              </div>
            )}
          </div>
          <div className="w-100">
            <Label className="fw-semibold">
              {t("Including VAT")} <span className="text-danger">*</span>
            </Label>
            <Controller
              name="prices[1].value"
              control={control}
              render={({ field }) => (
                <ReactInput
                  {...field}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
            {errors.prices?.[1]?.value && (
              <div className="text-danger">
                {t(errors.prices[1].value.message)}
              </div>
            )}
          </div>

          <div className="w-100">
            <Label className="fw-semibold">
              {t("MSRP")} 
            </Label>
            <Controller
              name="prices[2].value"
              control={control}
              render={({ field }) => (
                <ReactInput
                  {...field}
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
              )}
            />
            {errors.prices?.[2]?.value && (
              <div className="text-danger">
                {t(errors.prices[2].value.message)}
              </div>
            )}
          </div>
        </div>
        <div>
          <Label style={{ fontWeight: 550 }}>{t("Sale type")}</Label>
          <div className="d-flex gap-5">
          <div>
          <div className="form-check">
              <ReactInput
                type="checkbox"
                id="compensation"
                value={1}
                onChange={() => handleSaleTypeChange(1)}
                checked={saleTypeState.includes(1)}
              />
              <Label htmlFor="compensation">{t("Compensation")}</Label>
            </div>

            <div className="form-check">
              <ReactInput
                type="checkbox"
                id="installment"
                value={2}
                onChange={() => handleSaleTypeChange(2)}
                checked={saleTypeState.includes(2)}
              />
              <Label htmlFor="installment">{t("Installment")}</Label>
            </div>
          </div>

           <div>
           <div className="form-check">
              <ReactInput
                type="checkbox"
                id="paid"
                value={4}
                onChange={() => handleSaleTypeChange(4)}
                checked={saleTypeState.includes(4)}
              />
              <Label htmlFor="paid">{t("Sale")}</Label>
            </div>
            <div className="form-check">
              <ReactInput
                type="checkbox"
                id="bmt"
                value={8}
                onChange={() => handleSaleTypeChange(8)}
                checked={saleTypeState.includes(8)}
              />
              <Label htmlFor="bmt">BMT</Label>
            </div>
           </div>
          </div>
        </div>
     
       <div className="d-flex gap-2 flex-wrap">
      {fileInputs.map((input, index) => (
        <div
          key={index}
          style={{
            width: "300px",
            position: "relative",
          }}
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragOver={handleDragOver}
          onDrop={() => handleDrop(index)}
        >
          <Dropzone maxSize={maxFileSize}  onDropRejected={onDropRejected} onDropAccepted={onDropAccepted} disabled={filePreviews[index]} onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <div
                {...getRootProps({ className: "dropzone" })}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  height: "150px",
                  border: "2px dashed #cccccc",
                  borderRadius: "5px",
                  cursor: !filePreviews[index] ? "pointer" : "move",
                  backgroundColor: isDragActive ? "#e6f7ff" : isDragReject ? "#ffcccc" : "white", 
                }}
              >
                {!filePreviews[index] && (
                  <div className="d-flex align-items-center justify-content-center">
                    <i className="bx bx-image-add display-4"></i>
                  </div>
                )}
                {filePreviews[index] && (
                  <img
                    src={filePreviews[index]}
                    alt={`Preview ${index}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </div>
            )}
          </Dropzone>
          <Button
                style={{ position: "absolute", top: 0, right: 0 }}
                hidden={!filePreviews[index]}
                onClick={() => handleDeleteImage(index, input - 1)}
                color="danger"
              >
                Remove
              </Button>
        </div>
      ))}
    <div className="d-flex flex-column gap-1">
       {errors.files && (
          <span className="text-danger">{errors.files.message}</span>
        )} 
         <span ref={errorMessageRef} style={{ color: 'red', marginTop: '10px' }}></span>
       </div>
    </div>
        <div className="d-flex flex-column gap-3">
          {attributes?.item.map((item, idx) => (
            item.isVisible && (
              <div key={idx}>
              {item.filter ? (
                item.filter.filterType === "Range" ||
                item.filter.filterType === "Price" ? (
                  <div>
                    <div className="d-flex flex-column ">
                      <Label style={{ fontWeight: 550 }}>{item.name}</Label>
                      <span style={{ color: "gray" }}>
                        {item?.filter?.values}
                      </span>
                    </div>
                    <Input control={control} name={`attrs.${item.id}.value`} />
                  </div>
                ) : item.filter.filterType === "SingleSelect" ? (
                  <div>
                    <Label style={{ fontWeight: 550 }}>{item.name}</Label>
                    {item.filter.values.split(",").map((value, valueIdx) => (
                      <div key={valueIdx} className="form-check">
                        <ReactInput
                          name={`attrs.${item.id}.value`}
                          value={value}
                          type={"radio"}
                          checked={watch(`attrs.${item.id}.value`) === value}
                          onChange={() =>
                            setValue(`attrs.${item.id}.value`, value)
                          }
                          className="form-check-input"
                          id={value}
                        />
                        <Label htmlFor={value} className="form-check-label">
                          {value === "0" ? "Yes" : value === "1" ? "No" : value}
                        </Label>
                      </div>
                    ))}
                  </div>
                ) : item.filter.filterType === "MultipleSelect" ? (
                  <div>
                    <Label style={{ fontWeight: 550 }}>{item.name}</Label>
                    {item.filter.values.split(",").map((value, valueIdx) => (
                      <div key={valueIdx} className="form-check">
                        <Controller
                          control={control}
                          name={`attrs.${item.id}.value`}
                          render={({ field }) => {
                            const selectedValues = field.value
                              ? field.value.split(",")
                              : [];
                            const isChecked = selectedValues.includes(
                              value.trim()
                            );

                            return (
                              <>
                                <ReactInput
                                  {...field}
                                  type="checkbox"
                                  value={value.trim()}
                                  checked={isChecked}
                                  onChange={(e) => {
                                    const updatedValues = [...selectedValues];
                                    if (e.target.checked) {
                                      updatedValues.push(value.trim());
                                    } else {
                                      const index = updatedValues.indexOf(
                                        value.trim()
                                      );
                                      if (index > -1) {
                                        updatedValues.splice(index, 1);
                                      }
                                    }
                                    setValue(
                                      `attrs.${item.id}.value`,
                                      updatedValues.join(",")
                                    );
                                  }}
                                  className="form-check-input"
                                  id={value.trim()}
                                />
                                <Label
                                  htmlFor={value.trim()}
                                  className="form-check-label"
                                >
                                  {value}
                                </Label>
                              </>
                            );
                          }}
                        />
                      </div>
                    ))}
                    {errors.attribute &&
                      errors.attribute[idx] &&
                      errors.attribute[idx].value && (
                        <span className="text-danger">
                          {errors.attribute[idx].value.message}
                        </span>
                      )}
                  </div>
                ) : null
              ) : !item.isValueTranslated ? (
                <div>
                  <Label>{item.name}</Label>
                  <Input
                    control={control}
                    name={`attrs.${item.id}.value`}
                    errors={errors}
                  />
                  {errors.attribute &&
                    errors.attribute[item.id] &&
                    errors.attribute[item.id].value && (
                      <span className="text-danger">
                        {errors.attribute[item.id].value.message}
                      </span>
                    )}
                </div>
              ) : (
                item.isValueTranslated && (
                  <div >
                    <Label>{item.name}</Label>
                 <div className="d-flex  gap-3">
                 <div className="w-100">
                 
                  <InputLabel
                      control={control}
                      name={`attrs.${item.id}.valueTranslations.ru`}
                      inputProps={{
                        placeholder: t("in russian"),
                      }}
                      label={t("ru")}
                      errors={errors}
                    />
                    {errors.attrs &&
                      errors.attrs[item.id] &&
                      errors.attrs[item.id]?.valueTranslations && (
                        <span className="text-danger">
                          {
                            errors.attrs[item.id]?.valueTranslations?.ru
                              ?.message
                          }
                        </span>
                      )}
                  </div>
                   
                   <div className="w-100">
                   <InputLabel
                      control={control}
                      name={`attrs.${item.id}.valueTranslations.uz-Latn-UZ`}
                      inputProps={{
                        placeholder: t("in uzbek(lat)"),
                      }}
                      label={t("uz")}
                      errors={errors}
                    />
                    {errors.attrs &&
                      errors.attrs[item.id] &&
                      errors.attrs[item.id]?.valueTranslations && (
                        <span className="text-danger">
                          {
                            errors.attrs[item.id]?.valueTranslations?.[
                              "uz-Latn-UZ"
                            ]?.message
                          }
                        </span>
                      )}
                   </div>
                   <div className="w-100">
                   <InputLabel
                      control={control}
                      name={`attrs.${item.id}.valueTranslations.uz-Cyrl-UZ`}
                      inputProps={{
                        placeholder: t("in uzbek(kyr)"),
                      }}
                      label={t("cryl")}
                    />
                    {errors.attrs &&
                      errors.attrs[item.id] &&
                      errors.attrs[item.id]?.valueTranslations && (
                        <span className="text-danger">
                          {
                            errors.attrs[item.id]?.valueTranslations?.[
                              "uz-Cyrl-UZ"
                            ]?.message
                          }
                        </span>
                      )}
                   </div>
                 </div>
                  </div>
                )
              )}
            </div>
            )
          ))}
        </div>
        <div className="d-flex justify-content-end mt-3 mb-2 gap-2">
        <Button
                      type="button"
                      className="d-flex align-items-center gap-2"
                    >
             
                       
                      {t("add_attribute")}
                     
                    </Button>
          <Button
            className="btn btn-primary d-flex align-items-center gap-2  "
            type="submit"
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {t("Save")}
            <i className="ri-check-line"></i>
          </Button>
        </div>
      </Form>
      </CardBody>
     </Card>
     </Col>
    </div>
  );
};

export default ProductsEdit;
